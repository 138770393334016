import React, { useCallback } from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import { useState as useGlobalState } from 'state-pool';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { ReferenceLink } from '@frontastic-engbers/helpers/reference';
import { GlobalStateContext, useAccount, useCurrentShop } from '@frontastic-engbers/lib';
import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';
import { Amount, Item } from './index';
import { Image } from '@engbers/components';
import styles from './header-main.module.scss';
export const HeaderItem: React.FC<Item & Amount> = ({
  tabType,
  showLogoutBtnInHeader,
  itemIcon,
  itemLink,
  itemLabel,
  amount = null
}) => {
  const {
    getTitle
  } = useImageSEO();
  const {
    logout,
    loggedIn
  } = useAccount();
  const router = useRouter();
  const {
    formatMessage
  } = useFormat({
    name: 'account'
  });
  const currentSection = useCurrentShop();
  const handleLogout = useCallback(async () => {
    await logout();
    return router.push('/login');
  }, [logout, router]);
  return <div className={classnames(styles.itemLinksWrapper, {
    [styles.enGermanyItemLinksWrapper]: currentSection === 'engbers-germany'
  })} data-sentry-component="HeaderItem" data-sentry-source-file="header-item.tsx">
      <HeaderItemWrapper type={tabType} link={itemLink} data-sentry-element="HeaderItemWrapper" data-sentry-source-file="header-item.tsx">
        {itemIcon.media.format === 'svg' && <Image src={itemIcon.media.file} alt={getTitle(itemIcon)} title={getTitle(itemIcon)} />}
        <span>{itemLabel}</span>
        {amount && amount > 0 ? <span className={classnames(styles.amount, {
        [styles.wishlistAmount]: tabType === 'isWishlist',
        [styles.enGermanyAmount]: currentSection === 'engbers-germany'
      })} style={amount >= 100 ? {
        width: '22px',
        height: '22px',
        fontSize: '10px'
      } : undefined}>
            {amount >= 100 ? '+99' : amount}
          </span> : null}
      </HeaderItemWrapper>
      {tabType === 'isMyAccount' && showLogoutBtnInHeader && loggedIn ? <button className={styles.logoutButton} onClick={handleLogout}>
          {formatMessage({
        id: 'signout',
        defaultMessage: 'abmelden'
      })}
        </button> : null}
    </div>;
};
const HeaderItemWrapper: React.FC<{
  type: 'isMyAccount' | 'isWishlist' | 'isBasket';
  link: IFTLinkReference | IFTPageFolderReference;
}> = ({
  type,
  link,
  children
}) => {
  const router = useRouter();
  const [{
    isFlyoutCartOpen
  }, setGlobalState] = useGlobalState(GlobalStateContext);
  if (type === 'isBasket') {
    return <button className="basketButton" onClick={() => setGlobalState({
      isFlyoutCartOpen: router?.asPath?.includes('/cart') ? false : !isFlyoutCartOpen
    })}>
        {children}
      </button>;
  }
  return <ReferenceLink target={link} data-sentry-element="ReferenceLink" data-sentry-component="HeaderItemWrapper" data-sentry-source-file="header-item.tsx">{children}</ReferenceLink>;
};